<template>
  <div id="app">
    <div v-if="!isSiginedIn">
      <SignIn :title="title" @onSignedIn="onSignedIn($event)" />
    </div>
    <div v-else>
      <p id="sign-out">
        <SignOut @onSignedOut="onSignedOut()" />
      </p>
      <TweetCounter :token="token" @onAccessFailure="onAccessFailure($event)" />
    </div>
    <div v-if="errorMessage.length > 0">
      <br />
      <br />
      <div class="container alert alert-warning" role="alert">
        <pre>{{ errorMessage }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import SignIn from "./components/SignIn.vue";
import SignOut from "./components/SignOut.vue";
import TweetCounter from "./components/TweetCounter.vue";

export default {
  name: "App",
  components: {
    SignIn,
    SignOut,
    TweetCounter,
  },
  data() {
    return {
      title: "Welcome to Tweet Counter",
      googleUser: null,
      errorMessage: "",
      invalidUsers: [],
    };
  },
  computed: {
    token: function () {
      if (this.googleUser) {
        return this.googleUser.getAuthResponse().id_token;
      } else {
        return "";
      }
    },
    isSiginedIn: function () {
      return this.token && this.token.length > 0;
    },
  },
  methods: {
    onSignedIn(googleUser) {
      if (
        googleUser &&
        !this.invalidUsers.includes(googleUser.getBasicProfile().getEmail())
      ) {
        this.googleUser = googleUser;
        this.errorMessage = "";
      }
    },
    onSignedOut() {
      this.googleUser = null;
      this.errorMessage = "";
    },
    onAccessFailure(error) {
      console.log(error);
      this.errorMessage = JSON.stringify(error, null, 2);
      switch (error.error) {
        case "invalid_user":
          // 一度サインアウトしてサインインユーザーを変更する必要がある
          this.invalidUsers.push(this.googleUser.getBasicProfile().getEmail());
          this.googleUser = null;
          break;
        case "invalid_token":
          // token を再取得
          this.googleUser = null;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 30px;
}
#sign-out {
  text-align: right;
  padding-right: 10px;
}
</style>
