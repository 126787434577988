<template>
  <div id="sign-in">
    <h1>{{ title }}</h1>
    <br />
    <br />
    <br />
    <div v-if="googleUser">
      <img :src="googleUser.getBasicProfile().getImageUrl()" />
      <br />
      {{ googleUser.getBasicProfile().getEmail() }}
      <br />
      <br />
    </div>
    <GoogleLogin
      :params="params"
      :renderParams="renderParams"
      :onSuccess="onSuccess"
      :onFailure="onFailure"
      :onCurrentUser="onCurrentUser"
    ></GoogleLogin>
  </div>
</template>

<script>
import GoogleLogin from "vue-google-login";

export default {
  name: "SignIn",
  components: {
    GoogleLogin,
  },
  props: {
    title: String,
  },
  data() {
    return {
      googleUser: null,
      params: {
        client_id: process.env.VUE_APP_CLIENT_ID,
        prompt: "select_account",
      },
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
    };
  },
  methods: {
    onSuccess(googleUser) {
      this.next(googleUser);
    },
    onFailure(param) {
      console.log(param);
    },
    onCurrentUser(googleUser) {
      this.next(googleUser);
    },
    next(googleUser) {
      this.googleUser = googleUser;
      this.$emit("onSignedIn", googleUser);
    },
  },
};
</script>

<style>
#sign-in {
  text-align: center;
}
/* Google Signin ボタンを center にする */
.abcRioButton.abcRioButtonLightBlue {
  margin: 0 auto;
}
</style>
