<template>
  <div id="sign-out">
    <GoogleLogin
      class="btn btn-secondary"
      :logoutButton="true"
      :params="params"
      :onSuccess="onSuccess"
      :onFailure="onFailure"
      >Sign out</GoogleLogin
    >
  </div>
</template>

<script>
import GoogleLogin from "vue-google-login";

export default {
  name: "SignOut",
  components: {
    GoogleLogin,
  },
  data() {
    return {
      params: {
        client_id: process.env.VUE_APP_CLIENT_ID,
      },
    };
  },
  methods: {
    onSuccess() {
      this.$emit("onSignedOut");
    },
    onFailure(param) {
      console.log(param);
    },
  },
};
</script>
