<template>
  <div class="tweet-counter">
    <div class="graph-area container-fluid">
      <div class="container">
        <p>
          <button
            class="btn btn-primary"
            type="button"
            data-toggle="collapse"
            data-target="#collapseOption"
            aria-expanded="false"
            aria-controls="collapseOption"
            v-on:click="onClickedOptionButton"
          >
            Option
          </button>
        </p>
        <div class="collapse" id="collapseOption">
          <div v-if="showOption" class="card card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md">
                    <select v-model="period" @change="changedPeriod()">
                      <option
                        v-for="option in periodOptions"
                        :key="option.text"
                        v-bind:value="option.text"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md">
                    From
                    <vuejsDatepicker
                      id="start-at"
                      v-model="startAt"
                      :format="datePickerFormat"
                      @input="changedStartAt()"
                    ></vuejsDatepicker>
                  </div>
                  <div class="col-md">
                    To
                    <vuejsDatepicker
                      id="end-at"
                      v-model="endAt"
                      :format="datePickerFormat"
                      @input="changedEndAt()"
                    ></vuejsDatepicker>
                  </div>
                </div>
              </div>
              <div class="col-md-3" style="white-space: nowrap">
                <br />
                <label for="hourly">
                  <input
                    type="radio"
                    id="hourly"
                    value="hour"
                    v-model="interval"
                    @change="changedInterval()"
                  />
                  Hourly
                </label>
                <br />
                <label for="daily">
                  <input
                    type="radio"
                    id="daily"
                    value="day"
                    v-model="interval"
                    @change="changedInterval()"
                  />
                  Daily
                </label>
              </div>
            </div>

            <hr />

            <div v-for="(group, gk) in optionsByGroup" :key="gk" class="row">
              <div class="col-md-2" style="white-space: nowrap">
                <label :for="gk">
                  <input
                    :id="gk"
                    type="checkbox"
                    v-model="group.checked"
                    @change="changedGroupOption(gk)"
                  />
                  {{ gk }}</label
                >
              </div>
              <div v-for="(sub, sk) in group.subs" :key="sk">
                <div class="col-md-2" style="white-space: nowrap">
                  <label :for="sk">
                    <input
                      :id="sk"
                      type="checkbox"
                      v-model="sub.checked"
                      @change="changedSubGroupOption(gk, sk)"
                    />
                    {{ sk }}
                  </label>
                </div>
                <div v-for="x in sub.tags" :key="x.tag">
                  <div class="col-md-2" style="white-space: nowrap">
                    <label :for="x.tag">
                      <input
                        :id="x.tag"
                        type="checkbox"
                        v-model="x.checked"
                        @change="changedTagOption(gk, sk, x.tag)"
                      />
                      {{ x.tag }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Chart
        v-if="loaded"
        :chartdata="chartData"
        :options="chartOptions"
        :height="1000"
      />
    </div>
    <div class="tweets-area">
      {{ tweetsDescription }}
      <div v-for="tweet in tweets" :key="tweet.id">
        <blockquote class="twitter-tweet" data-lang="ja">
          <a :href="tweet.href"></a>
        </blockquote>
      </div>
    </div>
  </div>
</template>

<script>
import * as palette from "google-palette";
import Chart from "./Chart.vue";

export default {
  name: "TweetCounter",
  components: {
    // eslint-disable-next-line
    vuejsDatepicker,
    Chart,
  },
  props: {
    token: {
      type: String,
      default: "",
    },
  },
  data() {
    const now = new Date();
    return {
      periodOptions: [
        { text: "1 day", value: 1, unit: "day" },
        { text: "2 days", value: 2, unit: "day" },
        { text: "3 days", value: 3, unit: "day" },
        { text: "1 week", value: 7, unit: "day" },
        { text: "2 weeks", value: 14, unit: "day" },
        { text: "1 month", value: 1, unit: "month" },
        { text: "2 months", value: 2, unit: "month" },
        { text: "3 months", value: 3, unit: "month" },
        { text: "6 months", value: 6, unit: "month" },
      ],
      period: "2 days",
      startAt: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1),
      endAt: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
      datePickerFormat: "yyyy-MM-dd",
      interval: "hour",
      loaded: false,
      tcl: [],
      htl: [],
      showOption: false,
      shouldReload: false,
      optionsByGroup: {},
      tagColors: {},
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.onClick,
      },
      tweetsDescription: "",
      tweets: [],
    };
  },
  methods: {
    async initialize() {
      const url = new URL(this.url + "/hash_tags");
      const res = await fetch(url.toString(), {
        headers: { Authorization: "Bearer " + this.token },
      });
      const resJson = await res.json();
      if ("error" in resJson) {
        this.$emit("onAccessFailure", resJson);
        return;
      }
      this.htl = resJson.sort((a, b) => {
        if (a.member_id > b.member_id) {
          return 1;
        } else {
          return -1;
        }
      });
      this.optionsByGroup = this.makeOptionsByGroup(this.htl);
      this.tagColors = this.makeColors(this.htl, this.groups);
      this.load();
    },
    // tag の表示管理を group/sub_group でまとめる
    makeOptionsByGroup(htl) {
      const groups = {};
      for (let ht of htl) {
        const g = groups[ht.group] || {
          checked: true,
          subs: {},
        };
        const sg = g.subs[ht.sub_group] || {
          checked: true,
          tags: [],
        };
        sg.tags.push({ tag: ht.hash_tag, checked: true });
        g.subs[ht.sub_group] = sg;
        groups[ht.group] = g;
      }
      return groups;
    },
    // 予め hash_tag にユニークな色を割り当てる
    makeColors(htl) {
      const tagColors = {};
      const l = htl.map((x) => x.hash_tag);
      for (let i = 0; i < l.length; i++) {
        tagColors[l[i]] = "#" + palette("mpn65", l.length)[i];
      }
      return tagColors;
    },
    async load() {
      this.loaded = false;
      // API パラメータで指定する end_at はその時間を含まないため、nextDate() or nextHour() で次の時間を指定する
      let startAtStr, endAtStr, xAxis;
      if (this.interval == "hour") {
        startAtStr = this.formatDatetime(this.startAt);
        const now = new Date();
        if (this.formatDate(this.endAt) == this.formatDate(now)) {
          // end_at が今日の場合、現時刻の集計も含める
          endAtStr = this.formatDatetime(this.nextHour(now));
        } else {
          // end_at が今日ではない場合、翌日の 0:00 まで
          endAtStr = this.formatDate(this.nextDate(this.endAt));
        }
        const endAtQuery = new Date(endAtStr);
        const endAt = new Date(
          endAtQuery.getFullYear(),
          endAtQuery.getMonth(),
          endAtQuery.getDate(),
          endAtQuery.getHours() - 1
        );
        xAxis = this.makeDatetimeLabels(this.startAt, endAt);
      } else {
        startAtStr = this.formatDate(this.startAt);
        endAtStr = this.formatDate(this.nextDate(this.endAt));
        xAxis = this.makeDateLabels(this.startAt, this.endAt);
      }

      const url = new URL(this.url + "/tweet_counts");
      const params = new URLSearchParams();
      params.append("start_at", startAtStr);
      params.append("end_at", endAtStr);
      params.append("interval", this.interval);
      url.search = params.toString();
      const res = await fetch(url.toString(), {
        headers: { Authorization: "Bearer " + this.token },
      });
      this.tcl = await res.json();

      const showTags = this.getShowTags();
      this.chartData = this.makeChartdata(this.tcl, xAxis, showTags);
      this.loaded = true;

      // 一番盛り上がっている時間帯の tweet を表示する
      const maxCount = this.tcl
        .filter((x) => showTags.has(x.hash_tag))
        .reduce((a, b) => (a.count > b.count ? a : b));
      let startAtTweetStr, endAtTweetStr;
      if (this.interval == "day") {
        startAtTweetStr = maxCount.date;
        endAtTweetStr = this.formatDate(this.nextDate(new Date(startAtTweetStr)));
      } else {
        startAtTweetStr = maxCount.hour;
        endAtTweetStr = this.formatDatetime(this.nextHour(new Date(startAtTweetStr)));
      }
      this.loadTweets(maxCount.hash_tag, startAtTweetStr, endAtTweetStr);
    },
    nextDate(d) {
      return new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);
    },
    prevDate(d) {
      return new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);
    },
    nextHour(d) {
      return new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        d.getHours() + 1
      );
    },
    formatDate(d) {
      return (
        d.getFullYear() +
        "-" +
        this.pad(d.getMonth() + 1) +
        "-" +
        this.pad(d.getDate())
      );
    },
    formatDatetime(d) {
      return (
        this.formatDate(d) +
        "T" +
        this.pad(d.getHours()) +
        ":" +
        this.pad(d.getMinutes()) +
        ":" +
        this.pad(d.getSeconds())
      );
    },
    pad(num) {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? "0" : "") + norm;
    },
    makeChartdata(data, xAxis, showTags) {
      const dataByHashTag = this.splitByHashTag(data);
      const datasets = this.htl
        .filter((x) => showTags.has(x.hash_tag))
        .map((x) => ({
          label: x.hash_tag,
          data: xAxis.map((d) => {
            if (x.hash_tag in dataByHashTag && d in dataByHashTag[x.hash_tag]) {
              return dataByHashTag[x.hash_tag][d].count;
            } else {
              return 0;
            }
          }),
          borderColor: this.tagColors[x.hash_tag],
          fill: false,
          borderWidth: 2,
          tension: 0.2,
        }));

      return {
        labels: xAxis,
        datasets: datasets,
      };
    },
    makeDateLabels(startAt, endAt) {
      const labels = [];
      let d = startAt;
      while (d <= endAt) {
        labels.push(this.formatDate(d));
        d = this.nextDate(d);
      }
      return labels;
    },
    makeDatetimeLabels(startAt, endAt) {
      const labels = [];
      let d = startAt;
      while (d <= endAt) {
        labels.push(this.formatDatetime(d));
        d = this.nextHour(d);
      }
      return labels;
    },
    splitByHashTag(data) {
      const dataByHashTag = {};
      for (let x of data) {
        const d = dataByHashTag[x.hash_tag] || {};
        d[x.date || x.hour] = x;
        dataByHashTag[x.hash_tag] = d;
      }
      return dataByHashTag;
    },
    getShowTags() {
      let tags = [];
      for (let gk in this.optionsByGroup) {
        const group = this.optionsByGroup[gk];
        for (let sk in group.subs) {
          let sub = group.subs[sk];
          tags = tags.concat(
            sub.tags.filter((x) => x.checked).map((x) => x.tag)
          );
        }
      }
      return new Set(tags);
    },
    onClickedOptionButton() {
      this.showOption = !this.showOption;
      if (!this.showOption && this.shouldReload) {
        this.load();
        this.shouldReload = false;
      }
    },
    changedPeriod() {
      const p = this.periodOptions.find((x) => x.text == this.period);
      const now = new Date();
      const startAfter = this.prevDate(
        this.addDate(this.startAt, p.unit, p.value)
      );
      if (
        this.formatDate(now) == this.formatDate(this.endAt) ||
        now < startAfter
      ) {
        this.startAt = this.nextDate(
          this.addDate(this.endAt, p.unit, -p.value)
        );
      } else {
        this.endAt = startAfter;
      }

      if (this.addDate(this.startAt, "day", 10) <= this.endAt) {
        this.interval = "day";
      }
      this.shouldReload = true;
    },
    changedStartAt() {
      if (this.addDate(this.startAt, "day", 10) <= this.endAt) {
        this.interval = "day";
      }
      this.shouldReload = true;
    },
    changedEndAt() {
      if (this.addDate(this.startAt, "day", 10) <= this.endAt) {
        this.interval = "day";
      }
      this.shouldReload = true;
    },
    changedInterval() {
      this.shouldReload = true;
    },
    changedGroupOption(gk) {
      this.applyChangedGroupOption(gk);
      this.shouldReload = true;
    },
    addDate(d, unit, value) {
      switch (unit) {
        case "month":
          return new Date(d.getFullYear(), d.getMonth() + value, d.getDate());
        case "day":
          return new Date(d.getFullYear(), d.getMonth(), d.getDate() + value);
        default:
          new Error("Invalid unit");
      }
    },
    changedSubGroupOption(gk, sk) {
      this.applyChangedSubGroupOption(gk, sk);
      // 全ての sub-group が true なら group も true にする
      const group = this.optionsByGroup[gk];
      group.checked = Object.keys(group.subs)
        .map((sk) => group.subs[sk].checked)
        .reduce((acc, cur) => acc && cur);
      this.shouldReload = true;
    },
    changedTagOption(gk, sk) {
      // 全ての tags が true なら sub-group も true にする
      const group = this.optionsByGroup[gk];
      const sub = group.subs[sk];
      sub.checked = sub.tags
        .map((tag) => tag.checked)
        .reduce((acc, cur) => acc && cur);
      // 全ての sub-group が true なら group も true にする
      group.checked = Object.keys(group.subs)
        .map((sk) => group.subs[sk].checked)
        .reduce((acc, cur) => acc && cur);
      this.shouldReload = true;
    },
    applyChangedGroupOption(gk) {
      const group = this.optionsByGroup[gk];
      const newVal = group.checked;
      for (let sk in group.subs) {
        group.subs[sk].checked = newVal;
        this.applyChangedSubGroupOption(gk, sk);
      }
    },
    applyChangedSubGroupOption(gk, sk) {
      const group = this.optionsByGroup[gk];
      const sub = group.subs[sk];
      const newVal = sub.checked;
      for (let tag of sub.tags) {
        tag.checked = newVal;
      }
    },
    onClick(point, event) {
      const item = event[0];
      if ("_index" in (item || {})) {
        const datasetIdx = item._chart.getDatasetAtEvent(point)[0]
          ._datasetIndex;
        const tag = this.chartData.datasets[datasetIdx].label;
        const datetime = this.chartData.labels[item._index];
        const startAt = new Date(datetime);
        const endAtStr =
          this.interval == "hour"
            ? this.formatDatetime(this.nextHour(startAt))
            : this.formatDate(this.nextDate(startAt));

        this.loadTweets(tag, datetime, endAtStr);
      }
    },
    async loadTweets(tag, startAtStr, endAtStr) {
      this.tweetsDescription = `${tag}: ${startAtStr}`;
      this.tweets = [];

      const url = new URL(this.url + "/tweets");
      const params = new URLSearchParams();
      params.append("hash_tag", tag);
      params.append("start_at", startAtStr);
      params.append("end_at", endAtStr);
      url.search = params.toString();
      const res = await fetch(url.toString(), {
        headers: { Authorization: "Bearer " + this.token },
      });
      const tweets = await res.json();

      this.tweets = tweets.map((x) => ({
        href: `https://twitter.com/${x.user}/status/${x.id}`,
        datetime: x.datetime,
      }));
      // eslint-disable-next-line
      twttr.widgets.load(this.$el);
    },
  },
  mounted() {
    this.url = process.env.VUE_APP_API_BASE_URL;
    this.initialize();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tweet-counter {
  display: flex;
  text-align: left;
}
.graph-area {
  flex-grow: 1;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  padding: 10px;
  overflow: scroll;
}
.tweets-area {
  flex-shrink: 0;
  height: 100vh;
  max-height: 100vh;
  overflow: scroll;
  width: 480px;
  padding: 5px;
}
</style>
